import * as React from "react";
import { FlumaUserContext, showErrorAndSignOut } from "./fluma_api";
import { useState } from "react";
import LoginPage from "./login";
import {
  LinearProgress,
  Typography,
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  CircularProgress,
  Modal,
  Switch,
  FormControlLabel
} from "@mui/material";
import { UrlFlumaGenerateCode } from "./urls";
import { useContext } from "react";
import { FirebaseAuthContext } from "./firebase";
import { useEffect } from "react";
import { FigmaIntegrationSetupPage } from "./figma";
import { useParams } from "react-router-dom";
import {
  Archive,
  ArchiveOutlined,
  BugReport,
  Close,
  IosShare,
  Logout,
  Palette,
  Refresh,
} from "@mui/icons-material";
import { getAuth, signOut } from "firebase/auth";

async function getFigmaApiResponse(figmaFileId, figmaToken) {
  const response = await fetch(
    `https://api.figma.com/v1/files/${figmaFileId}?plugin_data=1032724369129598713&geometry=paths`,
    {
      headers: {
        Authorization: "Bearer " + figmaToken,
      },
    }
  );
  if (!response.ok) {
    console.error("Error fetching JSON response from Figma REST API", response);
    return response.statusText;
  }
  const jsonResponse = await response.json();
  console.log("Figma API response:", jsonResponse);
  return JSON.stringify(jsonResponse, null, 2);
}

// Returns { code: string, error: string }
async function generateCode(
  figmaFileId,
  token,
  figmaToken,
  nodeName,
  uploadImages
) {
  try {
    const params = new URLSearchParams();
    params.set("token", token);
    params.set("file", figmaFileId);
    if (uploadImages) {
      params.set("uploadImages", true);
    }
    if (nodeName) {
      params.set("nodeName", nodeName);
    }
    // TODO handle revoked/invalid figma token
    // TODO handle invalid file ID
    const response = await fetch(`${UrlFlumaGenerateCode}?${params}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  } catch (error) {
    showErrorAndSignOut(
      error,
      "There was an error while loading the application."
    );
  }
}

let didInjectDartpad = false;
let uploadedImages = false;

export default function DartPadPreview() {
  const authContext = useContext(FirebaseAuthContext);
  const userContext = useContext(FlumaUserContext);
  const { fileId, nodeName } = useParams();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const [openFigmaDebugModal, setOpenFigmaDebugModal] = React.useState(false);
  const [figmaDebugData, setFigmaDebugData] = React.useState("");

  const uploadImages = new URLSearchParams(window.location.search).get(
    "uploadImages"
  );

  useEffect(() => {
    (async () => {
      if (!authContext.initialized || !userContext.initialized) {
        return;
      }
      if (!userContext.figmaToken) {
        return;
      }
      if (authContext.user) {
        const output = await generateCode(
          fileId,
          await authContext.user.getIdToken(),
          userContext.figmaToken,
          nodeName,
          uploadImages
        );
        if (output.error) {
          setError(output.error);
          if (output.error.includes('{"status":403,"err":"Invalid token"}')) {
            userContext.onFigmaTokenError();
          }
          return;
        }
        setCode(output.code);
        if (!didInjectDartpad) {
          didInjectDartpad = true;
          const script = document.createElement("script");
          script.src = "https://dartpad.dev/inject_embed.dart.js";
          script.async = true;
          document.body.appendChild(script);
        }
        // Make DartPad full-screen
        let pollCount = 0;
        const poll = setInterval(() => {
          pollCount++;
          if (pollCount === 20) {
            alert(
              "Error: Dartpad editor took an unexpectedly long time to load."
            );
            clearInterval(poll);
          }
          if (document.getElementsByTagName("iframe").length) {
            document.getElementsByTagName("iframe")[0].style = `
              overflow:hidden;
              overflow-x:hidden;
              overflow-y:hidden;
              height:calc(100% - 64px);
              width:100%;
              position:absolute;
              top:64px;
              left:0px;
              right:0px;
              bottom:0px;
              border:0;`;
            clearInterval(poll);
            if (uploadImages) {
              uploadedImages = true;
              window.history.replaceState(
                null,
                document.title,
                window.location.origin + window.location.pathname
              );
            }
          }
        }, 100);
      }
    })();
  }, [authContext, userContext]);

  if (!authContext.initialized) {
    return <LinearProgress />;
  }
  if (!authContext.user) {
    return <LoginPage error={authContext.error} />;
  }
  if (!userContext.initialized) {
    return <LinearProgress />;
  }
  if (!userContext.figmaToken) {
    localStorage.setItem("figmaPreviewFileId", fileId);
    return <FigmaIntegrationSetupPage mode="app" />;
  }

  const renderCode = () => {
    if (!code && !error) {
      return <CircularProgress sx={{ mt: "auto" }} />;
    }
    if (error) {
      return <pre style={{ marginTop: "12em" }}>{error}</pre>;
    }
    var sampleCode = "";
    if (code.includes("// Sample Code (Fluma):")) {
      sampleCode = code.match(/\/\/ Sample Code \(Fluma\): (.*)/)[1];
    } else {
      sampleCode =
        (nodeName && isNaN(nodeName) && !nodeName.includes(":")
          ? nodeName
          : code.includes("class Main extends")
            ? "Main"
            : "$1") + "()";
    }
    if (code.includes("// Canvas Color (Fluma):")) {
      const canvasColor = code.match(/\/\/ Canvas Color \(Fluma\): (.*)/)[1];
      sampleCode = `Container(color: ${canvasColor} // Canvas Color\n, child: ${sampleCode})`;
    }
    return (
      <pre>
        <code className="language-run-dartpad:theme-dark:mode-flutter:run-true:split-50:width-100%:height-700px">
          {code.replace(/\/\/ Sample Code \(Fluma\): (.*)/, "").replace(
            /class (.*?) extends StatelessWidget/,
            `// Figma file: https://www.figma.com/file/${fileId}
// See console for REST API response from Figma

void main() => runApp(MyApp());

class MyApp extends StatelessWidget {
  @override Widget build(BuildContext context) {
    return MaterialApp(
      title: 'Fluma Demo',
      debugShowCheckedModeBanner: false,
      theme: ThemeData(
        primarySwatch: Colors.blue,
      ),
      home: Scaffold(body: ${sampleCode}),
  );
}
}

class $1 extends StatelessWidget`
          )}
        </code>
      </pre>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position="absolute" open={true}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <img src="/logo.png" width={70} height={25} alt="Fluma" />
            <Box m={1} />
            <Button variant="contained" size="small" disabled={true}>
              PREVIEW
            </Button>
          </Box>
          {isNaN(fileId) ? (
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  disabled={uploadedImages}
                  checked={uploadImages || uploadedImages}
                  onChange={() => {
                    if (uploadImages || uploadedImages) {
                      window.location.href = window.location.protocol + '//' + window.location.host + window.location.pathname;
                    } else {
                      window.location.search += "&uploadImages=1";
                    }
                  }}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: "0.9em", fontWeight: 500 }}>&nbsp; Show Images</Typography>}
              labelPlacement="end"
            />
          ) : <Button disabled variant='filled' size="small">
            Demo Mode
          </Button>}
          <Box m={1} />
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => window.open(`https://www.figma.com/file/${fileId}`)}
          >
            View in Figma
          </Button>
          <Box m={1} />
          <IconButton
            onClick={() => {
              if (window.confirm("Any changes to the code will be lost. Continue?")) {
                const iframes = document.getElementsByTagName('iframe');
                if (iframes.length > 0) {
                  const iframe = iframes[0];
                  iframe.src = iframe.src;
                }
              }
            }
            }
          >
            <Refresh />
          </IconButton>
          <IconButton
            onClick={() =>
              window.prompt(
                "Copy this URL to share this Fluma preview:",
                `https://fluma.io/preview/${fileId}`
              )
            }
          >
            <IosShare />
          </IconButton>
          {!isNaN(fileId) ? null : <IconButton
            onClick={() => {
              setOpenFigmaDebugModal(true);
              if (!figmaDebugData) {
                getFigmaApiResponse(fileId, userContext.figmaToken).then(
                  setFigmaDebugData
                );
              }
            }}
          >
            <BugReport />
          </IconButton>}
          {!isNaN(fileId) ? null : <IconButton
            onClick={() => {
              const params = new URLSearchParams();
              params.set("ft", userContext.figmaToken);
              params.set("file", fileId);
              if (window.confirm("Download the files for this project?")) {
                window.open(`/v1/download?${params}`);
              }
            }}
          >
            <ArchiveOutlined />
          </IconButton>}
          <IconButton href="/">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Modal
        open={openFigmaDebugModal}
        onClose={() => setOpenFigmaDebugModal(false)}
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "70%",
            backgroundColor: "#000",
            overflow: "scroll",
          }}
        >
          <pre style={{ margin: 12 }}>{figmaDebugData || "Loading..."}</pre>
        </Box>
      </Modal>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        {renderCode()}
      </Box>
    </Box>
  );
}
