import { useContext, useState, useEffect, createContext, useCallback } from 'react';
import { FirebaseAuthContext } from './firebase';
import { signOut, getAuth } from "firebase/auth";
import { UrlFlumaProduction, UrlFlumaUser, UrlUpdateFigmaToken } from './urls';

export const FlumaUserContext = createContext({
  initialized: false,
  uid: null,
  displayName: null,
  email: null,
  figmaToken: null,
  figmaCode: null,
  figmaUserId: null,
  onFigmaSignin: null,
  onFigmaTokenError: null,
});

export async function showErrorAndSignOut(error, message) {
  console.error(error);
  alert(message);
  await signOut(getAuth());
}

export function FlumaUserProvider(props) {
  const [initialized, setInitialized] = useState(false);
  const [figmaToken, setFigmaToken] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [figmaCode, setFigmaCode] = useState(null);
  const [figmaUserId, setFigmaUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [uid, setUid] = useState(null);
  const authContext = useContext(FirebaseAuthContext);

  async function fetchUser(initialParams) {
    try {
      const params = initialParams || new URLSearchParams();
      params.set('token', await authContext.user.getIdToken());
      const response = await fetch(`${UrlFlumaUser}?${params}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const jsonResponse = await response.json();
      if (jsonResponse.error) {
        throw new Error(jsonResponse.error);
      }

      setFigmaCode(jsonResponse.figmaCode);
      setFigmaToken(jsonResponse.figmaToken);
      setFigmaUserId(jsonResponse.figmaUserId);
      setEmail(jsonResponse.email);
      setUid(jsonResponse.uid);
      setDisplayName(jsonResponse.displayName);
      setInitialized(true);
    } catch (error) {
      showErrorAndSignOut(error, 'There was an error while loading the application.');
    }
  }

  useEffect(() => {
    if (authContext.initialized && authContext.user) {
      fetchUser();
    }
  }, [authContext]);

  const onFigmaTokenError = useCallback(() => {
    setFigmaCode(null);
    setFigmaToken(null);
    setFigmaUserId(null);
  });

  const onFigmaSignin = useCallback(async (code) => {
    try {
      const params = new URLSearchParams();
      params.set('token', await authContext.user.getIdToken());
      params.set('code', code);
      params.set('redirectUri', window.location.protocol + '//' + window.location.host + '/');
      const response = await fetch(`${UrlUpdateFigmaToken}?${params}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const { user_id, access_token } = await response.json();
      if (!user_id || !access_token) {
        throw new Error(response.statusText);
      }

      const fetchUserParams = new URLSearchParams();
      fetchUserParams.set('figmaCode', code);
      fetchUserParams.set('figmaToken', access_token);
      fetchUserParams.set('figmaUserId', user_id);
      await fetchUser(fetchUserParams);

      // Upload the Figma token to prod to avoid it from becoming invalid.
      (async () => {
        const params = new URLSearchParams();
        params.set('figmaCode', code);
        params.set('figmaToken', access_token);
        params.set('uid', authContext.user.uid);
        await fetch(`${UrlFlumaProduction}/debug/update-figma-token?${params}`);
      })();

      // Remove Figma callback code from url params.
      const replaceParams = new URLSearchParams(window.location.search);
      replaceParams.delete('code');
      replaceParams.delete('state');
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${replaceParams}${window.location.hash}`,
      );
    } catch (error) {
      await showErrorAndSignOut(error, 'There was an error while connecting with Figma.');
      window.location.replace('/');
    }
  });

  return (
    <FlumaUserContext.Provider value={{
      initialized,
      uid,
      displayName,
      email,
      figmaToken,
      figmaUserId,
      figmaCode,
      onFigmaSignin,
      onFigmaTokenError,
    }}>
      {props.children}
    </FlumaUserContext.Provider>
  );
}