export const LANDING = '/';
export const LOGIN = '/login';
export const FIGMA_SIGNIN_GITHUB = '/figma-signin-github';
export const FIGMA_SIGNIN_GOOGLE = '/figma-signin-google';
export const PASSWORD_RESET = '/reset-password';
export const PRIVACY = '/privacy';
export const TERMS_OF_USE = '/tou';
export const SUPPORT = '/support';
export const TUTORIAL = '/tutorial';
export const PREVIEW_NODE = '/preview/:fileId/:nodeName';
export const PREVIEW = '/preview/:fileId';
export const PREVIEW_HOME = '/preview';