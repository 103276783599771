import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import { githubSignin, googleSignin } from "./firebase";
import { useMediaQuery } from "@mui/material";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { PASSWORD_RESET, TUTORIAL } from "./routes";
import { useIsLargeScreen } from "./helpers";

export default function LoginPage(props) {
  const isLargeScreen = useIsLargeScreen()
  const [passwordError, setPasswordError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [unknownError, setUnknownError] = React.useState("");
  const [signinInProgress, setSigninInProgress] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    passwordSignin();
  };

  const passwordSignin = () => {
    setUnknownError("");
    if (passwordError || emailError) {
      return;
    }
    setSigninInProgress(true);
    const data = new FormData(document.getElementById("signin-form"));
    const email = data.get("email");
    const password = data.get("password");
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setSigninInProgress(false);
        // Automatically updates via auth provider
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode == "auth/wrong-password") {
          setPasswordError("Email or password is incorrect.");
          setSigninInProgress(false);
        } else if (
          errorCode == "auth/network-request-failed" ||
          errorCode == "auth/unknown"
        ) {
          setUnknownError(
            "There was an error while signing in. Please try again later."
          );
          setSigninInProgress(false);
        } else if (errorCode == "auth/user-not-found") {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              setSigninInProgress(false);
              // Automatically updates via auth provider
              // console.log(userCredential.user);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              if (errorCode == "auth/weak-password") {
                setPasswordError("Password should be at least 6 characters.");
              } else if (errorCode == "auth/invalid-email") {
                setEmailError("Invalid email address.");
              } else if (errorCode == "auth/email-already-in-use") {
                setEmailError("Email is already in use.");
              } else if (
                errorCode == "auth/network-request-failed" ||
                errorCode == "auth/unknown"
              ) {
                setUnknownError(
                  "There was an error while signing in. Please try again later."
                );
              } else {
                setUnknownError(
                  "There was an unknown error while signing in. Please try again later."
                );
                console.error(errorCode, errorMessage);
              }
              setSigninInProgress(false);
            });
        } else {
          setUnknownError(
            "There was an unknown error while signing in. Please try again later."
          );
          console.error(errorCode, errorMessage);
          setSigninInProgress(false);
        }
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={5}>
        <Box
          sx={{
            mb: 1,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div>
            <img src="/logo.png" width={85} alt="Fluma" />
            <br />
            <Typography component="p" variant="subtitle1">
              {props.error
                ? `⚠️ ${props.error}`
                : "Welcome! How would you like to connect?"}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              id="signin-form"
            >
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 5, mb: 2 }}
                startIcon={<GitHubIcon />}
                onClick={githubSignin}
                disabled={signinInProgress}
              >
                Sign In With Github
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 5 }}
                startIcon={<GoogleIcon />}
                onClick={googleSignin}
                disabled={signinInProgress}
              >
                Sign In With Google
              </Button>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={!!emailError}
                onChange={() => setEmailError("")}
                disabled={signinInProgress}
              />
              <div style={{ opacity: 0.5 }}>{emailError}</div>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!passwordError}
                onChange={() => setPasswordError("")}
                disabled={signinInProgress}
              />
              <div style={{ opacity: 0.5 }}>{passwordError}</div>
              <div style={{ opacity: 0.5 }}>{unknownError}</div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={passwordSignin}
                disabled={signinInProgress}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href={PASSWORD_RESET} variant="body2" underline="none">
                    {/* TODO implement */}
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" underline="none">
                    {/* TODO implement */}
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={7}
        sx={{
          backgroundColor: "#000918",
        }}
      >
        <Box
          sx={{
            p: isLargeScreen ? 2 : 0,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
            alignContent: "center",
          }}
          display={{ xs: "none", sm: "none", md: "block" }}
        >
          <img src="/heroblue2.png" style={{ width: '50%', alignSelf: 'center' }} alt="Fluma" />
          <Box sx={{ py: 5, px: isLargeScreen ? 10 : 3 }}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: 400,
              }}
            >
              Convert UI designs to production-ready Flutter components in seconds.
              <br />
              <br />
              <b>The best of both worlds. </b>
              Fluma is an interface layer between design and code that allows
              seamless UI changes with <b>zero</b> development cost.
              <br />
              <br />
              <Link href={TUTORIAL} underline="none">
                <Button variant="outlined" style={{ borderWidth: 2 }} >Show me how it works</Button>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
