import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  FormatLineSpacingTwoTone,
  KeyboardArrowRight,
  Person,
  QuestionAnswer,
  Star,
  Subject,
} from "@mui/icons-material";
import { signOut, getAuth } from "firebase/auth";
import {
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { FirebaseAuthContext } from "./firebase";
import { UrlFlumaProjects } from "./urls";
import { FlumaUserContext } from "./fluma_api";
import { PreviewInput } from "./preview_home";
import { TUTORIAL } from "./routes";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    "16 Mar, 2019",
    "Elvis Presley",
    "Tupelo, MS",
    "VISA ⠀•••• 3719",
    312.44
  ),
  createData(
    1,
    "16 Mar, 2019",
    "Paul McCartney",
    "London, UK",
    "VISA ⠀•••• 2574",
    866.99
  ),
  createData(
    2,
    "16 Mar, 2019",
    "Tom Scholz",
    "Boston, MA",
    "MC ⠀•••• 1253",
    100.81
  ),
  createData(
    3,
    "16 Mar, 2019",
    "Michael Jackson",
    "Gary, IN",
    "AMEX ⠀•••• 2000",
    654.39
  ),
  createData(
    4,
    "15 Mar, 2019",
    "Bruce Springsteen",
    "Long Branch, NJ",
    "VISA ⠀•••• 5919",
    212.79
  ),
];

function DashboardContent() {
  const isMobile = useMediaQuery("(max-width:600px)");

  // auto, close, open
  const [openDrawer, setOpenDrawer] = React.useState("auto");

  const [loading, setLoading] = React.useState(true);
  const [projects, setProjects] = React.useState([]);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const authContext = React.useContext(FirebaseAuthContext);
  const userContext = React.useContext(FlumaUserContext);

  React.useEffect(() => {
    (async () => {
      const params = new URLSearchParams();
      params.set("token", await authContext.user.getIdToken());
      const response = await fetch(`${UrlFlumaProjects}?${params}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setProjects(await response.json());
      setLoading(false);
    })();
  }, []);

  const toggleDrawer = () => {
    setOpenDrawer(openDrawer == "close" ? "open" : "close");
  };

  const isDrawerOpen = () => {
    return openDrawer == "auto" ? !isMobile : openDrawer == "open";
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={isDrawerOpen()}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(isDrawerOpen() && { display: "none" }),
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
          <Box sx={{ flexGrow: 1, mt: 1 }}>
            <img src="/logo.png" width={60} alt="Fluma" />
          </Box>
          <IconButton
            color="inherit"
            onClick={(event) => {
              setMenuAnchor(event.target);
            }}
          >
            <Person />
          </IconButton>
          <Menu
            id="profile-icon-menu"
            aria-labelledby="profile-icon-button"
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                setMenuAnchor(null);
              }}
            >
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                signOut(getAuth()).then(window.location.reload);
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={isDrawerOpen()}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <List>
          <ListItem
            button
            onClick={() => {
              // TODO
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="My Projects" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              // TODO
            }}
          >
            <ListItemIcon>
              <QuestionAnswer />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
          <ListItem button component={Link} href={TUTORIAL}>
            <ListItemIcon> 
              <Subject />
            </ListItemIcon>
            <ListItemText primary="Documentation" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <Star />
            </ListItemIcon>
            <ListItemText primary="Upgrade" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid item xs={12}>
            <Box p={2} />
            <Typography component="h1" variant="h5" gutterBottom>
              My Projects
            </Typography>
            {/* curl 'http://localhost:5002/v1/download?at=$FIGMA_ACCESS_TOKEN&file=2L3QqHgWLHS42uLKROsnZG' --output fluma.zip */}
            <Box p={1} />
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Figma File</TableCell>
                      <TableCell align="center">Flutter Version</TableCell>
                      <TableCell align="center">DartPad Preview</TableCell>
                      <TableCell align="right">Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell align="center">
                          <Link
                            target="_blank"
                            href={`https://figma.com/file/${row.figmaFileKey}`}
                            variant="body2"
                            underline="none"
                          >
                            {row.figmaFileKey}
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {row.flutterVersion}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            href={`/preview/${row.figmaFileKey}`}
                            variant="body2"
                            underline="none"
                          >
                            Preview
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            href={`/v1/download?ft=${userContext.figmaToken}&file=${row.figmaFileKey}`}
                            variant="body2"
                            underline="none"
                          >
                            Download (.tar.gz)
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
            <Box p={5} />
            <Typography component="h1" variant="h5" gutterBottom>
              Quick Preview
            </Typography>
            <Box p={1} />
            <Paper>
              <Box p={3}>
                <PreviewInput />
              </Box>
            </Paper>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export function Dashboard() {
  return <DashboardContent />;
}
