import * as React from "react";
import { FlumaUserContext, showErrorAndSignOut } from "./fluma_api";
import { useState } from "react";
import LoginPage from "./login";
import {
  LinearProgress,
  Typography,
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  CircularProgress,
  Modal,
  Paper,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { UrlFlumaGenerateCode } from "./urls";
import { useContext } from "react";
import { FirebaseAuthContext } from "./firebase";
import { useEffect } from "react";
import { FigmaIntegrationSetupPage } from "./figma";
import { useParams } from "react-router-dom";
import {
  BugReport,
  Close,
  IosShare,
  Logout,
  Palette,
} from "@mui/icons-material";
import { getAuth, signOut } from "firebase/auth";
import { useIsLargeScreen } from "./helpers";

export function PreviewInput() {
  const [key, setKey] = React.useState("");
  const [error, setError] = React.useState("");

  const submitPreview = () => {
    if (!key) {
      setError("Required");
      return;
    }
    let actualKey = "";
    let justKey = "";
    let nodeId = "";
    const [_, urlKey] = key.match(/figma\.com\/file\/([a-zA-Z0-9-_]+\b)/) || [];
    if (urlKey) {
      nodeId = (new URL(key)).searchParams.get('node-id') || '';
      justKey = urlKey;
      actualKey = urlKey + (nodeId ? '/' + nodeId : '');
      setKey(actualKey);
    } else {
      justKey = key;
      actualKey = key;
    }
    if (justKey.length < 10 || !justKey.match(/^[a-zA-Z0-9-_]+$/)) {
      setError("This doesn't look like a valid key.");
      return;
    }
    window.location.assign(`/preview/${actualKey}`);
  };

  return (
    <React.Fragment>
      <Typography component="p" variant="subtitle1">
        Convert a Figma file or component to Flutter and preview it on DartPad:
      </Typography>
      <Box height={30} />
      <TextField
        required
        fullWidth
        autoFocus
        error={Boolean(error)}
        helperText={error}
        label="Figma file key or URL"
        value={key}
        onChange={(event) => {
          setKey(event.target.value);
          setError("");
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            submitPreview();
          }
        }}
      />
      <Box height={30} />
      <Button variant="contained" onClick={submitPreview}>
        Let's go
      </Button>
    </React.Fragment>
  );
}

export function PreviewHome() {
  const isLargeScreen = useIsLargeScreen();

  const authContext = useContext(FirebaseAuthContext);
  const userContext = useContext(FlumaUserContext);

  useEffect(() => {
    (async () => {
      if (!authContext.initialized || !userContext.initialized) {
        return;
      }
      if (!userContext.figmaToken) {
        return;
      }
      if (authContext.user) {
        console.log("ok!");
      }
    })();
  }, [authContext, userContext]);

  if (!authContext.initialized) {
    return <LinearProgress />;
  }
  if (!authContext.user) {
    return <LoginPage error={authContext.error} />;
  }
  if (!userContext.initialized) {
    return <LinearProgress />;
  }
  if (!userContext.figmaToken) {
    return <FigmaIntegrationSetupPage mode="app" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position="absolute" open={true}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <img src="/logo.png" width={70} height={25} alt="Fluma" />
            <Box m={1} />
            <Button variant="contained" size="small" disabled={true}>
              PREVIEW
            </Button>
          </Box>
          <IconButton href="/">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexGrow: 1,
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{ width: isLargeScreen ? "50%" : "90%", p: 5 }}
          variant="outlined"
        >
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            sx={{ flexGrow: 1 }}
          >
            🎨 Quick Preview
          </Typography>
          <Box height={15} />
          <PreviewInput />
        </Paper>
      </Box>
    </Box>
  );
}
