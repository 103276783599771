import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme';
import { Container, Typography, Box, CssBaseline, Link } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Fluma LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function Privacy() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="md">
          <Box m={12} />
          <img src="/logo.png" width={70} alt="Fluma" />
          <Box m={1} />
          <Typography variant="h3" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Box m={16} />
          <Typography variant="h5" component="h2" gutterBottom>
            Introduction
          </Typography>
          <p>
            Fluma LLC (“Company,” “our,” “we”) respects your privacy and is committed to protecting it through our compliance with this Privacy Policy. This Privacy Policy is part of our <a class="Link" href="/tos">Terms of Use</a>.
          </p>
          <p>In this policy we describe the types of information we may collect from you or that you may provide when you use our mobile application (the “Application”) and our practices for collecting, using, protecting, and disclosing that information.</p>
          <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it, as you agree to this Privacy Policy by using the Application. If you do not agree with our policies and practices, do not use the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>Children Under the Age of 13</Typography>
          <p>The Application is not intended for children under 13 years of age and you are not allowed to use the Application or provide information on it if you are under 13 years of age. If we learn we have received personal information from or about a child under 13, we will delete that information immediately. If you believe we might have any information from or about a child under 13, please contact us immediately at <a class="Link" href="mailto:support@fluma.io">support@fluma.io</a>.
          </p>
          <Typography variant="h5" component="h2" gutterBottom>Information We Collect About You</Typography>
          <p>We collect basic information about users of the Application to help diagnose potential issues, namely:</p>
          <ul>
            <li>Information about your internet connection, the equipment you use to access the Application, and Application usage details.</li>
          </ul>
          <Typography variant="h5" component="h2" gutterBottom>How We Collect Information From You</Typography>
          <p>We collect generic information about your usage and equipment automatically as you navigate through the Application, such as usage details, app release, app version, device model, and time zone. Information that is automatically collected from you is used primarily for analytics purposes to improve the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>Do Not Track Signals</Typography>
          <p>The Application does not use cookies or advertisements, and since it is only available on your mobile phone, not in a web browser, we do not react to DNT signals because they do not apply to the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>How We Use Your Information</Typography>
          <p>We use information that we collect about you or that you provide to us, including any personal information to develop, provide, and improve the Application and its services to you.</p>
          <Typography variant="h5" component="h2" gutterBottom>Disclosure of Your Information</Typography>
          <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
          <p>We may disclose personal information that we collect or you provide:</p>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>To contractors, service providers, and other third parties we use to support our business, such as Amazon Web Services that hosts the Application.</li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about the Application users is among the assets transferred.</li>
            <li>To third party contractors in order to analyze Application performance and user behavior and/or to improve the Application, such as Mixpanel and Crashlytics.</li>
            <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
          </ul>
          <Typography variant="h5" component="h2" gutterBottom>Accessing and Correcting Your Information</Typography>
          <p>
            You can review and change your personal information by logging into the Application and visiting your account profile page. You may also send us an email at <a class="Link" href="mailto:support@fluma.io">support@fluma.io</a> to request access to, correct, or delete any personal information that you have provided to us. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
          </p>
          <Typography variant="h5" component="h2" gutterBottom>Data Security</Typography>
          <p>All information on the Application is encrypted when it is stored by Amazon Web Services and done exclusively through Secure Socket Layer technology (SSL) to prevent unauthorized parties from viewing such information.</p>
          <p>The safety and security of your information also depends on you. Do not share your passwords with anyone and be careful about giving out information in public areas of the Application. Additionally, because information is stored locally on your mobile phone, you, not the Company, are responsible for the protection and security of your mobile phone.</p>
          <Typography variant="h5" component="h2" gutterBottom>Third Party Links</Typography>
          <p>The Application may contain links that lead to other websites, such as suggestions on where to purchase certain items online. The Company is not responsible for the privacy practices, content, and/or activities of these linked websites. Nonetheless, we welcome any feedback about these external websites.</p>
          <Typography variant="h5" component="h2" gutterBottom>Changes to Our Privacy Policy</Typography>
          <p>We will post any changes we make to our privacy policy on this page and you will be notified of any material changes on the Application home page and in an email to the most recent email address that you provided to us, if any.</p>
          <Typography variant="h5" component="h2" gutterBottom>Contact Information</Typography>
          <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at <a class="Link" href="mailto:support@fluma.io">support@fluma.io</a>.
          </p>
          <Box m={12} />
          <Copyright />
          <Box m={12} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}