import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme';
import { Container, Typography, Box, CssBaseline, Link, TextField, TextareaAutosize, Button, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Copyright } from './copyright';

export function Support() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [url, setUrl] = useState('foobar');
  const [complete, setComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (error && (name || email || message)) {
      setError('');
    }
  }, [name, email, message]);

  const uploadAndComplete = async () => {
    try {
      const params = new URLSearchParams();
      params.set('name', name);
      params.set('email', email);
      params.set('url', url);
      params.set('msg', message);
      const response = await fetch(`/v1/support?${params}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const jsonResponse = await response.json();
      if (jsonResponse.error) {
        throw new Error(jsonResponse.error);
      }
      setComplete(true);
    } catch (error) {
      console.error(error);
      setError('There was an error while submitting your request. Please try again later.');
      setSubmitting(false);
      setComplete(false);
    }
  };

  const onSubmit = () => {
    if (!message) {
      setError('Please enter a message.');
      return;
    }
    setSubmitting(true);
    setTimeout(uploadAndComplete, 1000);
  };

  const form = () => {
    if (complete) {
      return (<Typography variant="h5" component="h2" gutterBottom>
        Thank you for your message!
      </Typography>);
    }
    return (<React.Fragment>
      <Typography variant="h5" component="h2" gutterBottom>
        Contact Us
      </Typography>
      <p>
        If you have a question or feedback about our product, we'd love to hear from you. Email us at support@fluma.io or use this contact form below to get in touch.
      </p>
      <Box m={4} />
      <Box maxWidth="sm">
        <TextField
          sx={{ my: 2 }}
          id="name"
          name="name"
          label="Name"
          fullWidth
          autoComplete="given-name"
          variant="filled"
          value={name}
          onChange={(event) => setName(event.target.value)}
          disabled={submitting}
        />
        <TextField
          sx={{ my: 2 }}
          id="email"
          name="email"
          label="Email"
          fullWidth
          autoComplete="email"
          variant="filled"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled={submitting}
        />
        <TextField
          id="url"
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          sx={{ maxHeight: 0, opacity: 0, disabled: true, cursor: 'pointer' }}
        />
        <TextField
          variant="filled"
          id="comment"
          label="Your Message"
          multiline
          rows={4}
          fullWidth
          disabled={submitting}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        <Box my={4} />
        {error}
        <Box my={4} />
        {submitting ? (
          <LinearProgress />) : (
          <Button variant="contained" onClick={onSubmit}>Submit</Button>
        )}
      </Box>
      <Box m={12} />
      <Copyright />
    </React.Fragment>)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="md">
          <Box m={12} />
          <img src="/logo.png" width={70} alt="Fluma" />
          <Box m={1} />
          <Typography variant="h3" component="h1" gutterBottom>
            Support
          </Typography>
          <Box m={16} />
          {form()}
          <Box m={12} />
        </Container>
      </Box >
    </ThemeProvider >
  );
}