import { useState, useContext, Fragment, useEffect } from 'react';
import { FirebaseAuthProvider, FirebaseAuthContext } from './firebase';
import { LinearProgress, Typography, Box, CssBaseline, Toolbar, IconButton, AppBar, Card, Paper, Stack, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import { githubSignin, googleSignin } from './firebase';
import { Dashboard } from './dashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ArrowForwardIos, ChevronRight, ChevronRightRounded, Logout } from '@mui/icons-material';
import { signOut, getAuth } from "firebase/auth";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from "react-router-dom";
import React from 'react';
import { FlumaUserContext, FlumaUserProvider } from './fluma_api';
import { UrlFigmaAuthUpdate } from './urls';

export function FigmaSigninPage(props) {
  const authContext = useContext(FirebaseAuthContext);
  const userContext = useContext(FlumaUserContext);

  const [uploaded, setUploaded] = useState(false);
  const [completedSignIn, setCompletedSignIn] = useState(false);
  const [completedFigmaIntegration, setCompletedFigmaIntegration] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const key = new URLSearchParams(window.location.search).get('key');

  async function uploadLoginData(key, user, error) {
    let params = new URLSearchParams();
    params.set('key', key);
    if (error) {
      params.set('error', error);
    } else {
      params.set('uid', user.uid);
      params.set('ut', await user.getIdToken());
      params.set('rft', user.refreshToken);
      params.set('email', user.email);
    }
    try {
      const response = await fetch(`${UrlFigmaAuthUpdate}?${params}`);
      const jsonResponse = await response.json();
      if (jsonResponse.error) {
        alert('There was an error while uploading the login result to Fluma.');
      }
    } catch (error) {
      console.error(error);
      alert('There was an error while uploading the login result to Fluma.');
    }
  }

  useEffect(() => {
    (async () => {
      if (!authContext.initialized) {
        return;
      }
      if (authContext.user || authContext.error) {
        if (!uploaded) {
          setUploaded(true);
          await uploadLoginData(key, authContext.user, authContext.error);
          setCompletedSignIn(true);
        }
      } else if (!signingIn) {
        setSigningIn(true);
        setTimeout(() => {
          if (props.providerName === 'google') {
            googleSignin();
          } else if (props.providerName === 'github') {
            githubSignin();
          }
        }, 400);
      }
    })();
  }, [authContext]);

  useEffect(() => {
    if (userContext.figmaCode) {
      setCompletedFigmaIntegration(true);
    } else if (userContext.initialized) {
      window.location.replace('/');
    }
  }, [userContext])

  if (key === null) {
    window.location.replace('/');
    return null;
  }

  if (!authContext.initialized) {
    return <LinearProgress />;
  }

  if (completedSignIn && !completedFigmaIntegration) {
    return <LinearProgress />;
  }

  if (completedFigmaIntegration) {
    return (
      <Fragment>
        <CssBaseline />
        <Box
          sx={{
            my: 4,
            mx: 4,
          }}
        >
          <Typography component="p" variant="subtitle1">
            {authContext.error ? `⚠️ ${authContext.error}` : 'Login complete. Please return to Figma to continue.'}
          </Typography>
          <Box m={1} />
          <Button
            variant="outlined"
            sx={{ mt: 1, mb: 5 }}
            onClick={() => window.close()}
          >
            Close Window
          </Button>
        </Box>
      </Fragment>);
  }
  return (
    <Fragment>
      <CssBaseline />
      <LinearProgress />
      <Box
        sx={{
          my: 4,
          mx: 4,
        }}
      >
        <Typography component="p" variant="subtitle1">
          Please wait...
        </Typography>
      </Box>
    </Fragment>);
}

export function FigmaIntegrationSetupPage(props) {
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  const figmaCode = new URLSearchParams(useLocation().search).get('code');
  const flumaUser = useContext(FlumaUserContext);

  useEffect(() => {
    if (figmaCode) {
      flumaUser.onFigmaSignin(figmaCode);
    }
  }, []);

  function figmaIntegrationSignin() {
    let params = new URLSearchParams();
    params.set('client_id', 'sBZjPzQWAYtcOko80ijC5F');
    params.set('redirect_uri', window.location.protocol + '//' + window.location.host + '/');
    params.set('scope', 'file_read');
    params.set('state', ':state');
    params.set('response_type', 'code');
    window.location.href = `https://www.figma.com/oauth?${params}`;
  }

  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
    }}>
      <CssBaseline />
      {props.mode == "figma" ? null : (
        <AppBar position="absolute" open={true}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <img src="/logo.png" width={70} alt="Fluma" />
            </Box>
            <IconButton onClick={() => signOut(getAuth())}>
              <Logout />
            </IconButton>
          </Toolbar>
        </AppBar>)}
      <Box
        component="main"
        sx={{
          display: 'flex', flexGrow: 1,
          alignSelf: 'center', justifyContent: 'center',
        }}
      >
        <Paper sx={{ width: isLargeScreen ? '50%' : '90%', p: 5 }} variant='outlined'>
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            sx={{ flexGrow: 1 }}
          >
            ⚡️ Connect with Figma
          </Typography>
          <Box height={15} />
          <Typography component="p" variant="subtitle1">
            Authenticate with Figma to allow Fluma to access your files for preview and export.
          </Typography>
          <Box height={30} />
          {figmaCode ? <LinearProgress /> : (
            <Button
              fullWidth
              variant='contained'
              startIcon={<ChevronRightRounded />}
              onClick={figmaIntegrationSignin}
            >
              Connect with Figma
            </Button>)}
        </Paper>
      </Box>
    </Box >);
}