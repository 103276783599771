import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as AppRoutes from "./routes";
import LoginPage from "./login";
import { ThemeProvider } from "@mui/material/styles";
import { FirebaseAuthProvider, FirebaseAuthContext } from "./firebase";
import { FigmaIntegrationSetupPage, FigmaSigninPage } from "./figma";
import { FlumaUserContext, FlumaUserProvider } from "./fluma_api";
import { Dashboard } from "./dashboard";
import { Privacy } from "./pages/privacy";
import { TermsOfUse } from "./pages/terms_of_use";
import { theme } from "./theme";
import { LinearProgress, Typography, Box, CssBaseline } from "@mui/material";
import { Support } from "./pages/support";
import DartPadPreview from "./preview";
import { PreviewHome } from "./preview_home";
import { HowToPage } from "./pages/howtopage";
import { PasswordReset } from "./pages/password_reset";

function App() {
  const figmaPreviewFileId = localStorage.getItem("figmaPreviewFileId");
  if (figmaPreviewFileId) {
    localStorage.removeItem("figmaPreviewFileId");

    // Figma doesn't allow dynamic callbacks
    if (document.referrer.indexOf("figma.com") !== -1) {
      window.location.replace(
        `/preview/${figmaPreviewFileId}${window.location.search}`
      );
      return null;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <FirebaseAuthProvider>
        <FlumaUserProvider>
          <BrowserRouter>
            <Routes>
              <Route path={AppRoutes.LANDING} exact element={<LandingPage />} />
              <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
              <Route
                path={AppRoutes.FIGMA_SIGNIN_GITHUB}
                element={<FigmaSigninPage providerName="github" />}
              />
              <Route
                path={AppRoutes.FIGMA_SIGNIN_GOOGLE}
                element={<FigmaSigninPage providerName="google" />}
              />
              <Route path={AppRoutes.PRIVACY} element={<Privacy />} />
              <Route path={AppRoutes.TERMS_OF_USE} element={<TermsOfUse />} />
              <Route path={AppRoutes.SUPPORT} element={<Support />} />
              <Route path={AppRoutes.TUTORIAL} element={<HowToPage />} />
              <Route path={AppRoutes.PREVIEW} element={<DartPadPreview />} />
              <Route
                path={AppRoutes.PREVIEW_NODE}
                element={<DartPadPreview />}
              />
              <Route path={AppRoutes.PREVIEW_HOME} element={<PreviewHome />} />
              <Route path={AppRoutes.PASSWORD_RESET} element={<PasswordReset />} />
            </Routes>
          </BrowserRouter>
        </FlumaUserProvider>
      </FirebaseAuthProvider>
    </ThemeProvider>
  );
}

function LandingPage() {
  return (
    <FirebaseAuthContext.Consumer>
      {({ initialized, user, error }) => {
        if (!initialized) {
          return <LinearProgress />;
        }
        if (user) {
          return <SignedInLandingPage />;
        }
        return <LoginPage error={error} />;
      }}
    </FirebaseAuthContext.Consumer>
  );
}

function SignedInLandingPage() {
  return (
    <FlumaUserContext.Consumer>
      {({ initialized, figmaToken }) => {
        if (!initialized) {
          return <LinearProgress />;
        }
        if (!figmaToken) {
          return <FigmaIntegrationSetupPage mode="app" />;
        }
        return <Dashboard />;
      }}
    </FlumaUserContext.Consumer>
  );
}

export default App;
