import { useState, useEffect, createContext } from 'react';
import * as Firebase from 'firebase/app';
import {
  getAuth, getRedirectResult, GithubAuthProvider, GoogleAuthProvider,
  signInWithRedirect, onAuthStateChanged
} from "firebase/auth";
import { UrlFlumaUser } from './urls';

const firebaseConfig = {
  // Copied in figma-rpcapi.ts
  apiKey: "AIzaSyAel9wu838FOPaf1HlNocuBgsh7HMAFCsg",
  authDomain: "fluma-c4674.firebaseapp.com",
  projectId: "fluma-c4674",
  storageBucket: "fluma-c4674.appspot.com",
  messagingSenderId: "68705627011",
  appId: "1:68705627011:web:047055b99e005f054fe4b6",
  measurementId: "G-C15V73FW63"
};

export const FirebaseAuthContext = createContext({
  initialized: false,
  user: null,
  error: null,
});

const app = Firebase.initializeApp(firebaseConfig);
const auth = getAuth();

function signin(provider) {
  provider.setCustomParameters({
    'allow_signup': 'false'
  });

  signInWithRedirect(auth, provider);
}

export function githubSignin() {
  signin(new GithubAuthProvider());
}

export function googleSignin() {
  signin(new GoogleAuthProvider());
}

export function FirebaseAuthProvider(props) {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  async function updateUser(user) {
    try {
      // Create a Fluma user profile
      const params = new URLSearchParams();
      params.set('token', await user.getIdToken());
      params.set('rft', user.refreshToken);
      params.set('uid', user.uid);
      params.set('displayName', user.displayName);
      params.set('email', user.email);
      const response = await fetch(`${UrlFlumaUser}?${params}`);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setUser(user);
    } catch (error) {
      setError('Unable to create a user profile. Please sign out and log in again.');
      console.error(error);
    }
  }

  useEffect(() => {
    (async () => {
      onAuthStateChanged(auth, async (user) => {
        try {
          const result = await getRedirectResult(auth);
          await updateUser(result.user);

          // // This gives you a GitHub Access Token. You can use it to access the GitHub API.
          // const credential = GithubAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
        } catch (error) {
          if (user) {
            setUser(user);
          } else {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.email;
            if (errorCode === undefined) {
              // No signin.
            }
            else if (errorCode === 'auth/account-exists-with-different-credential') {
              setError('You have signed up with a different provider for that email.');
              console.error('You have signed up with a different provider for that email.');
            }
            else {
              setError('Unknown error. Please try again.');
              console.error(email, errorMessage, errorCode);
            }
            setUser(null);
          }
        }

        setInitialized(true);
      });
    })();
  }, []);

  return (
    <FirebaseAuthContext.Provider value={{ initialized, user, error }}>
      {props.children}
    </FirebaseAuthContext.Provider>
  );
}