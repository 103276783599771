import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme';
import { Container, Typography, Box, CssBaseline, Link } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Fluma LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function TermsOfUse() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="md">
          <Box m={12} />
          <img src="/logo.png" width={70} alt="Fluma" />
          <Box m={1} />
          <Typography variant="h3" component="h1" gutterBottom>
            Terms of Use
          </Typography>
          <Box m={16} />
          <Typography variant="h5" component="h2" gutterBottom>
            Introduction
          </Typography>
          <p>Please read these Terms of Use and our <a class="Link" href="/privacy">Privacy Policy</a> (collectively the “Terms”) carefully, as you agree that you consent to these Terms by your use of this application and Fluma LLC's services, as described further below.</p>
          <Typography variant="h5" component="h2" gutterBottom>Acceptance of the Terms of Use</Typography>
          <p>These terms of use are entered into by and between you and Fluma LLC (“Company,” “we,” “our,” or “us”), and they govern your access to and use of this application (“Application”), including any content, functionality, and services offered on or through the Application, whether as a guest or a registered user.</p>
          <p>Please read the Terms carefully before you start to use the Application. By using the Application, or by agreeing to these Terms by creating an account on the Application, you agree to be bound by our Terms. If you do not agree to these Terms of Use or the <a class="Link" href="/privacy">Privacy Policy</a>, do not access or use the Application or any Fluma LLC services, but please get in touch with us so we can try to help.</p>
          <p>If you have questions about our billing procedures, see the Subscriptions and Payments section below.</p>
          <Typography variant="h5" component="h2" gutterBottom>Term</Typography>
          <p>This agreement remains in full force and effect while you use the Application and provisions that would naturally survive after you stop using the Application of Fluma LLC’s services will remain in effect.</p>
          <Typography variant="h5" component="h2" gutterBottom>Eligibility</Typography>
          <p>The Application is intended only for access and use by individuals at least thirteen (13) years old. If you are not at least thirteen (13) years old, you are prohibited from using the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>Changes to the Terms of Use</Typography>
          <p>We may revise and update the Terms from time to time in our sole discretion. All changes are effective immediately when we post them, and your continued use of the Application following the posting of revised Terms means you agree to the changes.</p>
          <Typography variant="h5" component="h2" gutterBottom>Suggested Resources</Typography>
          <p>The Application may include content or items provided by third parties. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties on or through the Application.</p>
          <p>We have the right to terminate, suspend access to, or disable any account at any time in our sole discretion, including if, in our opinion, you have violated any provision of the Terms.</p>
          <Typography variant="h5" component="h2" gutterBottom>Changes and Downtime on the Application</Typography>
          <p>We may update the content on the Application from time to time, but its content will not always be complete or up-to-date. Any of the material on the Application may be out of date at any given time, and it is the responsibility of the user to ensure there have not been changes to the material from official sources.</p>
          <p>We reserve the right to withdraw or amend the Application, and any service or material we provide on the Application, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Application is unavailable at any time or for any period.</p>
          <Typography variant="h5" component="h2" gutterBottom id="subscriptions">Subscriptions; Free Trial; Payments</Typography>
          <p>The Company may allow you to try the Application with a Free Trial. When you sign up for the Free Trial, you will be asked to authorize payment for a subscription to the Application at the end of the Free Trial period. If you do not cancel the subscription by the end of the Free Trial period, your account will be automatically charged for the subscription. If you sign up for a subscription prior to the end of the Free Trial, any unused portion of the Free Trial period is forfeited. The current subscription options, including the rates, are listed in the Application.</p>
          <p>All subscriptions automatically renew, unless you cancel them from your iTunes account at least 24 hours before the end of the current billing period. Accounts will be charged for renewal within 24 hours prior to the end of the current period. Refunds will not be given once a payment has been made, but you can cancel the subscription at any time to ensure that future payments are not made. The Company reserves the right to modify its fee or payment structure at any time. If the fee increases, you will be asked to agree to the new rate, otherwise your subscription will expire at the end of your current billing cycle.</p>
          <p>All payments for the Application are controlled and managed by Apple. Payments will be charged to your iTunes account upon confirmation of purchase.</p>
          <p>For more information about payments, cancellations, or managing your subscriptions and auto-renewal, please contact Apple or go to your iTunes account settings within your mobile device. Apple also offers the following information about managing your Apple subscriptions:
            <a href="https://support.apple.com/en-us/HT202039">https://support.apple.com/en-us/HT202039</a>.
          </p>
          <Typography variant="h5" component="h2" gutterBottom>Taxes</Typography>
          <p>You are solely liable for all the taxes, fees, and commissions resulting from use of the Application. These Terms of Use do not create a joint venture, partnership, or principal-agent relationship between you and the Company, and nothing in these Terms may be used to imply such a relationship.</p>
          <Typography variant="h5" component="h2" gutterBottom>Feedback</Typography>
          <p>In the event that you provide us any feedback related to Application, you agree we may use the feedback to improve and promote Application in any manner we choose, and that you will not be due any compensation for your feedback that is used in these ways.</p>
          <Typography variant="h5" component="h2" gutterBottom>Sharing Your Information</Typography>
          <p>The information that we collect on the Application is governed by our <a class="Link" href="/privacy">Privacy Policy</a>. You may also choose to share information from the Application voluntarily through social media platforms, and such sharing is done at your own risk and discretion.
          </p>
          <Typography variant="h5" component="h2" gutterBottom>Licensed Application</Typography>
          <p>If you download the Application, you agree and understand that the Application is licensed, not sold, to you for use only under these Terms. The Company reserves all rights not expressly granted to you. This license is in addition to any license terms required by Apple, Google, or any other source from which you may download the Application, and all are binding on you. In the event that this license conflicts with other license terms, these terms control.</p>
          <p>This license granted to you for the Application by the Company is limited to a non-transferable license to use the Application on any device that you own or control and as permitted by these Terms and any other terms that may apply when you download the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>Intellectual Property Rights</Typography>
          <p>The Application and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned by the Company, its licensors, or other providers of such material and are protected by United States or international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
          <p>These Terms of Use permit you to use the Application for your personal, non-commercial use only, subject to the following restrictions:</p>
          <ul>
            <li>You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, decompile, reverse engineer, disassemble, attempt to derive the source code of, or transmit the Application or any material on the Application, except as it is created and owned by you.</li>
            <li>You must not delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>
            <li>You must not access or use for any commercial purposes any part of the Application or any services or materials available through the Application.</li>
          </ul>
          <Typography variant="h5" component="h2" gutterBottom>Links from the Application</Typography>
          <p>If the Application contains links to other sites and resources provided by third parties, these links are provided for your convenience only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to the Application, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>
          <Typography variant="h5" component="h2" gutterBottom>Users Outside the U.S.</Typography>
          <p>The Application is controlled and operated from the United States and is subject to its laws. If you choose to access the Application outside of the United States, you do so at your own risk and are responsible for complying with all applicable laws, rules, and regulations.</p>
          <Typography variant="h5" component="h2" gutterBottom>Disclaimer of Warranties</Typography>
          <p>YOUR USE OF THE APPLICATION, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION IS AT YOUR OWN RISK. THE APPLICATION, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE APPLICATION. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE APPLICATION, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE APPLICATION OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE APPLICATION OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
          <p>THE APPLICATION IS FOR CONSUMER EDUCATIONAL USE ONLY. NOTHING CONTAINED IN THE APPLICATION IS OR SHOULD BE CONSIDERED, OR USED AS A SUBSTITUTE FOR ANY PROFESSIONAL ADVICE.</p>
          <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
          <Typography variant="h5" component="h2" gutterBottom>Limitation on Liability</Typography>
          <p>IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APPLICATION, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE APPLICATION, OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS, OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>
          <p>IN NO EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE) EXCEED THE AMOUNT YOU HAVE PAID TO THE COMPANY FOR THE APPLICABLE SERVICES IN THE LAST 12 MONTHS OUT OF WHICH LIABILITY AROSE.</p>
          <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
          <Typography variant="h5" component="h2" gutterBottom>Indemnification</Typography>
          <p>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of the Terms or your use of the Application, including, but not limited to, your use of the Application’s content, services, and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>Governing Law</Typography>
          <p>All matters, disputes, and claims relating to the Application and the Terms are governed by the laws of the Canada without giving effect to any conflict of law provisions or rules.</p>
          <Typography variant="h5" component="h2" gutterBottom>Arbitration</Typography>
          <p>At the Company’s sole discretion, it may require you to submit any disputes arising from the use of the Terms or the Application, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the Canadian Arbitration Association applying Canadian law.</p>
          <Typography variant="h5" component="h2" gutterBottom>Waiver and Severability</Typography>
          <p>No waiver by the Company of any term or condition set forth in the Terms shall be deemed a continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under the Terms shall not constitute a waiver of such right or provision.</p>
          <p>If any provision of the Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.</p>
          <Typography variant="h5" component="h2" gutterBottom>Entire Agreement</Typography>
          <p>The Terms of Use and our <a class="Link" href="/privacy">Privacy Policy</a> constitute the sole and entire agreement between you and the Company with respect to the Application and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Application.</p>
          <Typography variant="h5" component="h2" gutterBottom>Your Comments and Concerns</Typography>
          <p>The Application is operated by Fluma LLC, a Canadian Federal Private Corporation.</p>
          <p>All feedback, comments, requests for technical support and other communications relating to the Application should be directed to <a class="Link" href="mailto:support@fluma.io">support@fluma.io</a>.</p>
          <Box m={12} />
          <Copyright />
          <Box m={12} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}