import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";
import {
  Container,
  Typography,
  Box,
  CssBaseline,
  Link,
  TextField,
  TextareaAutosize,
  Button,
  LinearProgress,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  SwipeableDrawer,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Copyright } from "./copyright";
import {
  ChevronLeft,
  Circle,
  CircleOutlined,
  InboxOutlined,
  MailOutline,
  OpenInNew,
} from "@mui/icons-material";
import { FirebaseAuthContext } from "../firebase";

export function HowToPage() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [section, setSection] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      {isMobile ? (
        <IconButton
          onClick={toggleDrawer(true)}
          disableFocusRipple
          sx={{ bgcolor: "#151f2f", position: "fixed", right: 20, top: 20 }}
        >
          <ChevronLeft />
        </IconButton>
      ) : null}
      <SwipeableDrawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <List
          sx={{
            width: 40 * 8,
            paddingTop: 8,
            bgcolor: "rgba(255,255,255,0.06)",
            height: "100%",
          }}
        >
          <FirebaseAuthContext.Consumer>
            {({ initialized, user, error }) => {
              if (!initialized) {
                return <LinearProgress />;
              }
              if (user) {
                return (
                  <Link href="/" underline="none">
                    <Box m={2}>
                      <Button variant="contained">Go to Dashboard</Button>
                    </Box>
                  </Link>
                );
              }
              return (
                <Link href="/" underline="none">
                  <Box m={2}>
                    <Button variant="contained">Sign In</Button>
                  </Box>
                </Link>
              );
            }}
          </FirebaseAuthContext.Consumer>
          <Box m={8}></Box>
          {[
            "Introduction",
            "Quick Start",
            "Figma Plugin",
            "Widget Parameters",
            "Reusing Components",
            "Repeating Parameters",
            "Component Variants",
            "Shared styles and theme data",
            "Export files",
          ].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton disableRipple>
                <ListItemIcon>
                  {index === 0 ? (
                    <Circle sx={{ color: "#005AF7" }} />
                  ) : (
                    <CircleOutlined sx={{ opacity: 0.2 }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          paddingRight: isMobile ? 0 : 40,
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="md">
          <Box m={12} />
          <img src="/logo.png" width={70} alt="Fluma" />
          <Box m={1} />
          <Typography variant="h3" component="h1" gutterBottom>
            Figma to Flutter in One Click
          </Typography>
          <p>Tutorial · January 2023 · 10 min read</p>
          <Box m={8} />
          <p>
            Fluma is a tool for generating high-quality and production-ready
            Flutter widget code from Figma. This tutorial will walk you through the basics as well as
            all of the features Fluma has to offer.
          </p>
          <p>
            First, let's start in Figma. If you do not already have a design, we have provided a
            sample file of a to-do app that can be used for this tutorial.
          </p>
          <Box m={4} />
          <Link target="_blank" href="https://www.figma.com/file/OES5c80JTx20zt1qwBSZFW">
            <Box
              height={isMobile ? 300 : 400}
              sx={{
                bgcolor: "rgba(255,255,255,0.05)",
                borderRadius: 3,
                position: "relative",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <a href="https://www.figma.com/file/OES5c80JTx20zt1qwBSZFW">
                <img
                  src="/figmalogo.png"
                  width={30}
                  height={30}
                  alt="Figma logo"
                  style={{ position: "absolute", right: 12, top: 18 }}
                />
              </a>
              <img
                src="/sampleFrame1.png"
                width={"35%"}
                alt="Sample Frame 1"
                style={{ margin: '10% 0 0 10%', borderRadius: 10 }}
              />
              <img
                src="/sampleFrame2.png"
                width={"35%"}
                alt="Sample Frame 2"
                style={{ margin: '10% 0 0 10%', borderRadius: 10 }}
              />
            </Box>
          </Link>
          <p style={{ opacity: 0.6 }}>
            <b>New to Figma?</b> Figma is a cloud-based design tool with excellent
            prototyping capabilities and collaboration features. It is easy to
            learn and very intuitive, which is ideal for new designers, yet its
            advanced features are powerful enough for prototyping.
          </p>
          <p style={{ opacity: 0.4 }}>
            Try Figma <Link
              href={"https://figma.com/"}
              underline="none"
            >here</Link> or learn more on their <Link
              href={"https://www.youtube.com/c/Figmadesign"}
              underline="none"
            >
              Figma YouTube channel
            </Link>
            .
          </p>
          <Box m={4} />
          <Link
            href="https://www.figma.com/file/OES5c80JTx20zt1qwBSZFW"
            underline="none"
            target="_blank"
          >
            <Button
              startIcon={<OpenInNew />} variant="contained">Open Sample File in Figma</Button>
          </Link>
          <Box m={10} />
          <Typography variant="h4" component="h1" gutterBottom>
            Quick Start
          </Typography>
          <Box m={4} />
          <p>
            The easiest way to get started is to use the{" "}
            <Link
              href="https://fluma.io/preview"
              underline="none"
              target="_blank"
            >
              Web Preview
            </Link>{" "}
            tool.
          </p>
          <p>
            Open the Figma file you would like to use to generate
            Flutter code. Click the “Share” button on the top right corner. It
            looks like this:
          </p>
          <img src="/share.png" width={150} alt="Fluma" />
          <p>
            <b>Step 3.</b> Click on “🔗 Copy link”
          </p>
          <p>
            <b>Step 4.</b> Open{" "}
            <Link
              href="https://fluma.io/preview"
              underline="none"
              target="_blank"
            >
              fluma.io/preview
            </Link>{" "}
            in the browser, sign in, and paste the link.
          </p>
          <Box m={4} />
          <img src="/screenshare.png" width={400} alt="Fluma" />
          <Box m={10} />
          <Typography variant="h4" component="h1" gutterBottom>
            Figma Plugin
          </Typography>
          <Box m={4} />
          <p>
            Take full advantage of all the functionality that Fluma provides by installing the Figma plugin.
          </p>
          <p>
            <ul>
              <li>
                <b>Export complete workspaces</b> to production-ready <i>.dart</i> files and drop them into your project.
              </li>
              <li>
                <b>Generate shared styles</b> to a separate <i>styles.dart</i> file that
                can be integrated directly into your ThemeData.
              </li>
              <li><b>Disable</b> certain nodes from being exported.</li>
              <li>
                <b>Customize widget names</b>.
              </li>
              <li>
                <b>Customize the widget type</b> for a node. For example, a
                Frame node may be a <i>Stack</i> or a <i>Column</i>.
              </li>
              <li>
                <b>Bind node attributes to widget parameters</b>. For example, a node
                wrapped in a <i>GestureDetector</i> may expose an <i>onTap</i>{" "}
                handler on its corresponding Flutter widget.
              </li>
              <li>
                <b>Bind repeated items</b> inside a frame with auto layout to <i>List</i> parameters.
              </li>
            </ul>
          </p>
          <Box m={6} />
          <Typography variant="h5" component="h2" gutterBottom>
            Installation
          </Typography>
          <Box m={4} />
          <Link
            href="https://www.figma.com/community/plugin/778755750523021654/Flutter-Export"
            underline="none"
            target="_blank"
          >
            <Button
              startIcon={<OpenInNew />} variant="contained">Click here to install</Button>
          </Link>
          <p style={{ opacity: 0.6 }}>
            Once installed, you will be automatically be taken to <b>Demo mode</b> where you can click on any node to preview generated Flutter code. You are limited to generate only a few nodes at a time at the beginning, and you need to upgrade to remove this limit.
          </p>
          <Box m={10} />
          <Typography variant="h4" component="h1" gutterBottom>
            Widget Parameters
          </Typography>
          <Box m={4} />
          <p>
            Customize the generated Flutter widget code directly within the Fluma plugin without touching any code.
            Parameter binding allows you to bind certain data or properties
            The best part about this feature is that you can modify 
            Let's start with a basic example of personal contact card
          </p>
          <Box m={20} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
