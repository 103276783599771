import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { LOGIN, TUTORIAL } from "../routes";
import { useIsLargeScreen } from "../helpers";

export function PasswordReset(props) {
  const isLargeScreen = useIsLargeScreen()
  const [resetEmail, setResetEmail] = React.useState(false);
  const [invalidEmailError, setInvalidEmailError] = React.useState(false);
  const [missingEmailError, setMissingEmailError] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email !== "") {
      resetPassword();
    } else {
      setMissingEmailError(true);
    }
  };

  // Attempt to reset password
  // Show error if email is invalid
  const resetPassword = async () => {
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmail(true);
      setInvalidEmailError(false);
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setInvalidEmailError(true);
        setResetEmail(false);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={5}>
        <Box
          sx={{
            mb: 1,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img src="/logo.png" width={85} alt="Fluma" />
            <br />
            <Typography component="p" variant="subtitle1">
              {props.error
                ? `⚠️ ${props.error}`
                : "What is your email? We'll recover your password for you."}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              id="reset-email-form"
            >
              <TextField
                margin="normal"
                error={invalidEmailError || missingEmailError}
                helperText={
                  invalidEmailError
                    ? "⚠️ No such email exists"
                    : missingEmailError
                    ? "Please enter an email"
                    : ""
                }
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setMissingEmailError(false);
                }}
                autoComplete="email"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Recover
              </Button>
              <Link href={LOGIN} underline="none">
                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Sign in
                </Button>
              </Link>
              {resetEmail && (
                <div>
                  <Typography component="p">
                    Password reset request sent
                  </Typography>
                  <Typography component="p">
                    Please check your spam folder too
                  </Typography>
                </div>
              )}
            </Box>
          </div>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={7}
        sx={{
          backgroundColor: "#000918",
        }}
      >
        <Box
          sx={{
            p: isLargeScreen ? 2 : 0,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
            alignContent: "center",
          }}
          display={{ xs: "none", sm: "none", md: "block" }}
        >
          <img
            src="/heroblue2.png"
            style={{ width: "50%", alignSelf: "center" }}
            alt="Fluma"
          />
          <Box sx={{ py: 5, px: isLargeScreen ? 10 : 3 }}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: 400,
              }}
            >
              Convert UI designs to production-ready Flutter components in
              seconds.
              <br />
              <br />
              <b>The best of both worlds. </b>
              Fluma is an interface layer between design and code that allows
              seamless UI changes with <b>zero</b> development cost.
              <br />
              <br />
              <Link href={TUTORIAL} underline="none">
                <Button variant="outlined" style={{ borderWidth: 2 }}>
                  Show me how it works
                </Button>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
