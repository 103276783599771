import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#005AF7',
        },
        secondary: {
            main: '#FFFFFF',
        },
        background: {
            default: '#000E20',
            paper: '#000E20',
        },
        text: {
            primary: '#ffffff',
            secondary: 'rgba(255,255,255,0.7)',
        },
    },
    typography: {
        fontFamily: 'Inter',
        h1: {
            fontFamily: 'Times',
            fontWeight: 700,
            fontSize: 42,
        },
        h2: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: 42,
        },
        h3: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: 42,
        },
        h4: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: 42,
        },
        h6: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: 42,
        },
        h5: {
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: 35,
        },
        body1: {
            fontFamily: 'Inter',
            fontSize: 14,
        },
        body2: {
            fontFamily: 'Inter',
            fontSize: 14,
        },
        subtitle1: {
            fontFamily: 'Inter',
            fontSize: 14,
        },
        subtitle2: {
            fontFamily: 'Inter',
            fontSize: 14,
        },
        button: {
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: 17,
            textTransform: 'capitalize'
        },
        overline: {
            fontFamily: 'Inter',
        },
    },
});