import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../theme';
import { Container, Typography, Box, CssBaseline, Link, TextField, TextareaAutosize, Button, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

export function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Fluma LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
